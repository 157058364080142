<!--
 * @Author: Tom
 * @Date: 2022-06-28 11:00:02
 * @LastEditors: Eric
 * @Description: 
-->
<template>
  <div class="edp-backend">
    <edp-backend-user />

    <section class="edp-backend-middle">
      <edp-backend-menu />
      <section class="edp-backend-content">
        <div class="edp-backend-content-out">
          <mainTitle> </mainTitle>
          <div class="app-container shadow">
            <div class="check-quest">
              <div class="total-head">
                <el-image
                  style="width: 70px; height: 70px"
                  :src="info.coverImg"
                ></el-image>
                <div class="t-content">
                  <div>
                    <span class="hot">{{ info.title }}</span>
                    <span
                      >{{$t('questionnaire.Totalviews')}}
                      <span class="hot">{{
                        info.browsingHistoryNum
                      }}</span></span
                    >
                    <span
                      >{{$t('questionnaire.totalComplete')}}
                      <span class="hot">{{ info.totalNum }}</span></span
                    >
                  </div>
                  <div>
                    <span
                      >{{$t('questionnaire.todayNew')}}
                      <span class="hot">{{ info.todayNum }}</span></span
                    >
                    <span
                      >{{$t('questionnaire.averageTime')}}
                      <span class="hot">{{ info.avgTakeTime }}</span></span
                    >
                    <!-- <span>答题有效率 <span class="hot">0</span></span> -->
                  </div>
                </div>
                <div class="operation">
                  <span @click="exportList" class="export"
                    ><i class="el-icon-upload2"></i>&nbsp;{{$t('questionnaire.exportData')}}</span
                  >
                  <el-button @click="toGo" type="transparent">{{$t('edpBackendCommon.return')}}</el-button>
                </div>
              </div>

              <el-row :gutter="20">
                <el-col :span="24" class="searchBtn">
                  <el-table
                    size="medium"
                    :data="questionData"
                    style="width: 100%; margin-top: 20px"
                  >
                    <el-table-column prop="code" :label="$t('questionnaire.Evaluationorder')" />
                    <el-table-column :label="$t('questionnaire.Answerer')">
                      <template slot-scope="scope">{{
                        scope.row.respondent || "-"
                      }}</template></el-table-column
                    >
                    <!-- <el-table-column :label="$t('questionnaire.Answerer')">
                      <template slot-scope="scope">{{
                        scope.row.respondentMobile || "-"
                      }}</template></el-table-column
                    > -->
                    <el-table-column prop="takeTime" :label="$t('questionnaire.Answerduration')" />
                    <el-table-column prop="answerTime" :label="$t('questionnaire.Answertime')" />
                    <el-table-column
                      prop="publishSystemsStr"
                      :label="$t('questionnaire.Answerposition')"
                    />
                    <el-table-column :label="$t('edpBackendCommon.operate')">
                      <template slot-scope="scope">
                        <span @click="check(scope.row)" class="span-btn"
                          >{{$t('edpBackendCommon.view')}}</span
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="table-footer">
                    <span>{{ total }}条记录，共{{ totalPage }}页</span>
                    <el-pagination
                      :total="total"
                      :current-page="pageNo"
                      background
                      hide-on-single-page
                      layout="prev, pager, next"
                      @current-change="currentChange"
                    />
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
// import {
//   surveyAnswer,
//   surveyAnswerExport
// } from "@/api/questionList";
// import draggable from "vuedraggable";
// import { upLoadFile } from "@/api/member";
// import { tableList } from "@/api/coupon";
// import breadcrumb from "@/components/breadcrumb";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "CheckQuestionnaires",
  components: {
    // draggable,
    // breadcrumb,
  },
  data() {
    return {
      quertionsTit: [
        {
          key: "id",
          value: "答题序号",
        },
        {
          key: "que1",
          value: "题目1",
        },
      ],
      answers: [
        {
          id: "1",
          que1: "答案1",
        },
        {
          key: "que1",
          value: "题目1",
        },
      ],
      pageSize: 10,
      total: 0,
      pageNo: 1,
      totalPage: 0,
      questionData: [],
      info: {
        browsingHistoryNum: 0,
        title: "",
        totalNum: 0,
        todayNum: 0,
        avgTakeTime: 0,
        coverImg: null,
      },
    };
  },
  computed: {},
  mounted() {
    this.intiData();
    try {
      this.info = JSON.parse(window.sessionStorage.getItem("surveyAnswerInfo"));
    } catch (error) {}
  },
  methods: {
    ...mapActions({
      surveyAnswer: "survey/surveyAnswer",
      surveyAnswerExport: "survey/surveyAnswerExport",
    }),
    currentChange(num) {
      this.pageNo = num;
      this.intiData();
    },
    async intiData() {
      const { id } = this.$route.query;
      const params = {
        current: this.pageNo,
        size: this.pageSize,
        id,
      };
      const res = await this.surveyAnswer(params);
      if (res.code === "0") {
        this.questionData = res.records;
        this.total = res.total;
        this.totalPage = res.totalPage;
      }
    },
    async exportList() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { id } = this.$route.query;
      const res = await this.surveyAnswerExport({
        id,
      });
      console.log(res);
      const name = "问卷答案导出表.xlsx";
      const blob = new Blob([res]);
      const url = window.URL.createObjectURL(blob);
      const aLink = document.createElement("a");
      aLink.style.display = "none";
      aLink.href = url;
      aLink.setAttribute("download", name);
      document.body.appendChild(aLink);
      aLink.click();
      document.body.removeChild(aLink); // 下载完成移除元素
      window.URL.revokeObjectURL(url); // 释放掉blob对象
      loading.close();
      this.$message({
        type: "success",
        message: this.$t("edpBackendCommon.actionSuccess"),
      });
    },
    toGo() {
      this.$router.go(-1);
    },
    downFunction(link) {
      var eleLink = document.createElement("a");
      eleLink.href = link;
      eleLink.target = "_blank";
      eleLink.down = "";
      eleLink.style.display = "none";
      document.body.appendChild(eleLink);
      eleLink.click();
      document.body.removeChild(eleLink);
    },
    async check(data) {
      const { id } = this.$route.query;
      this.$router.push({
        path: "/backend-addQuestionnaires",
        query: {
          type: 1,
          id,
          answerId: data.id,
        },
      });
    },
  },
};
</script>
<style scoped lang="less">
.check-quest {
  padding: 10px;
  .total-head {
    display: flex;

    .pic {
      width: 200px;
      height: 200px;
    }
  }
  .t-content {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;

    div {
      span {
        display: inline-block;
        padding-right: 40px;
        color: #999;
        font-weight: 500;
      }
      .hot {
        color: #002961;
      }
    }
  }
  .operation {
    position: absolute;
    height: 70px;
    right: 30px;
    top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .export {
      display: inline-block;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #dcdfe6;
      border-color: #dcdfe6;
      color: #002961;
      border-radius: 4px;
      padding: 12px 10px;
    }
  }
}
</style>
